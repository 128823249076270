<script lang="ts">
	import DynamicZone from '$lib/modules/cms/DynamicZone/DynamicZone.svelte'
	import StrapiHeader from '$lib/modules/cms-headers/StrapiHeader.svelte'

	export let title: string | null = null
	export let type: 'productPage' | 'landingPage' | string
	export let header: any = null
	export let components: any
</script>

{#if header}
	{#key header}
		<StrapiHeader headerData={header} />
	{/key}
{/if}

<main class="page-wrapper z-20 mb-16 flex flex-col items-center">
	{#if type === 'landingPage' && title}<h1 class="!mb-12 text-center">{@html title}</h1>{/if}

	{#if components}
		<div class="flex w-full flex-col gap-4">
			<DynamicZone componentData={components} />
		</div>
	{/if}
</main>
